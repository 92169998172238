import React from "react"
import { useGlobalEvent, useDidMount, useInterval } from 'beautiful-react-hooks';

import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

import "../styles/Globals.scss"

import Products from "../data/products.json"

const mainTitle = "Lemmerliese";


function changeImage() {
  let notshown = document.querySelectorAll('#Items .animate:not(.shown)');
  let showit = notshown[Math.floor(Math.random() * notshown.length)];

  document.querySelectorAll('#Items .shown').forEach(function (element) {
    if (!element.classList.contains('transition')) {
      element.classList.remove("shown");
    }
  });

  //console.log(showit)
  if (!showit.classList.contains('transition')) {
    showit.classList.add("shown");
  }
  //useTimeout(changeImage(), (Math.floor(Math.random() * 1250) + 750));
}

const ProductsListing = () => {

  useInterval(() => {
    //console.log('useInterval');
    changeImage();
  }, 2750);

  function randomBGColor() {
    var items = ["blue", "indigo", "purple", "pink", "red", "orange", "yellow", "green", "teal", "cyan",
    "blue2", "indigo2", "purple2", "pink2", "red2", "orange2", "yellow2", "green2", "teal2", "cyan2"];
    var item = items[Math.floor(Math.random() * items.length)];
    return item;
  }

  return (
    <div className="row no-gutters row-cols-4 mb-5" id="Items">

      {Products.map((item, index) => (
        /* data-href={item.href} */
        <div className={"col bg-" + (item.bg ? item.bg : randomBGColor()) + (item.src2 ? ' animate' : '') + (item.both ? ' both' : '')} key={index} data-section={item.section} >
          <img src={item.src} alt={item.alt} className="img-fluid" />
          {item.src2 && <><img src={item.src2} alt={item.alt} className="img-fluid fg" /></>}
        </div>
      ))}
    </div>
  )
}

const Home = () => {


  useGlobalEvent('resize', null, (event) => {
    document.querySelectorAll('#Items .col').forEach(function (element) {
      element.style.height = element.clientWidth + 'px';
    });
    //console.log(window.innerWidth);
  });

  useDidMount(() => {
    document.querySelectorAll('#Items .animate').forEach(function (element) {
      element.classList.remove('transition');
      element.addEventListener('mytransitionstart', function (e) { console.log(e); element.classList.add('transition'); });
      element.addEventListener('mytransitionend', function (e) { console.log(e); element.classList.remove('transition'); });
      /* ['transitionstart', 'MSTransitionStart', 'webkitTransitionStart', 'oTransitionStart'].forEach(function (evt) {
        element.addEventListener(evt, function () { element.classList.add('transition'); });
      });
      ['transitionend', 'MSTransitionEnd', 'webkitTransitionEnd', 'oTransitionEnd'].forEach(function (evt) {
        element.addEventListener(evt, function () { element.classList.remove('transition'); });
      }); */

    });

    document.querySelectorAll('#Items .col').forEach(function (element) {

      element.style.height = element.clientWidth + 'px';
/*       element.style.cursor = 'pointer';
 */      element.addEventListener('click', function (e) {
        // wäremekissen  33826518
        // mäppchen 33863836
        // geldbeutel 33865106
        // kosmetik 33866114
        let url = element.dataset?.section ? "https://www.etsy.com/de/shop/Lemmerliese?section_id=" + element.dataset.section : (element.dataset?.href ? element.dataset.href : '');
        console.log(element.dataset?.section);
        console.log(element.dataset?.href);
        console.log(url);
        if (url !== '') {
          window.open(url, '_blank');
        }

      });

    });
  });

  return (
    <Layout mainTitle={mainTitle}>
      <Helmet htmlAttributes={{ lang: 'de' }}>
        <body className="homepage" />
        <meta name="description" content="Selbstgemachte Wärmekissen, Geldbeutel, Kosmetiktaschen für Neugeborene, kleine und große Leute. Jeden Menge anderer genähter Schnickschnack in charmanten Farben und hübschen Stoffen" />
        <meta name="keywords" content="Wärmekissen, Geldbeutel, Kosmetiktaschen, Selbstgemachte Geschenke, DIY, Nähen, Sticken, Stricken" />
      </Helmet>
      <article className="container">
        <ProductsListing />

        <div className="row">

          <div className="col-12 my-5">
            <p>Hi, ich bin Sandra von Lemmerliese und nähe, sticke und stricke seit vielen Jahren für zwei Läden in Heidelberg.</p>
            <p>Für <a href="https://www.freitagskind.de/" target="_blank" rel="noreferrer">Freitagskind</a> fertige ich liebevolle Geschenke für Neugeborene kleine und große Leute, z.B. flauschige Wärmekissen und Täschchen für jeden Schnickschnack in charmanten Farben und hübschen Stoffen.</p>
{/*             <p>Jetzt könnt ihr all dies auch in meinem <a href="https://www.etsy.com/de/shop/Lemmerliese" target="_blank" rel="noreferrer">Etsy Shop</a> kaufen. Bitte stöbert und lasst euch inspirieren.</p>
 */}            <p>Für mehr Infos oder Fragen, schickt mir einfach eine <a href="mailto:info@lemmerliese.de?subject=Kontaktaufnahme">Mail</a> - obwohl ich mehr nähe, als am Computer zu sitzen, bemühe ich mich so schnell wie möglich zu antworten.</p>
            <p>Eure Sandra</p>
          </div>
        </div>
      </article>
    </Layout >)
}

export default Home;
